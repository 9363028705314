import {
  ReviewResponseTicket as ReviewResponseTicketPb,
  ReviewResponseTicketFilters,
} from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_pb';

const { Status: FilterStatus } = ReviewResponseTicketFilters;
const { Type: TicketStatus } = ReviewResponseTicketPb.Status;

/**
 * Map: filterStatusToTicketStatusMap maps the filters used on erc-frontend to all ticket statuses
 * that fall under that filter.
 * * https://erc.development.friendemic.com/storybook/index.html?path=/story/global-enums--enums
 *
 * * TO-BE DEPRECATED IN FAVOR OF DIRECTLY USING TICKET STATUSES
 *
 * @example
 * REFERENCE:
 * Previous Bucketing Logic
 * const filters_bucket_map = {
 *   [UNKNOWN]: [],
 *   [ACTIVE]: [
 *     UNKNOWN,
 *     EDITS_REQUESTED,
 *     APPROVED_PENDING_PUBLISH,
 *   ],
 *   [PENDING]: [
 *     RESPONSE_SUBMITTED
 *   ],
 *   [PENDING_PUBLISH]: [
 *     PUBLISHED
 *   ],
 *   [DONE]: [
 *     UNAVAILABLE,
 *     COMPLETED,
 *     COMPLETED_EXTERNAL,
 *   ],
 *   [UPDATED]: [
 *     UPDATED
 *   ],
 * };
 */
export const filterStatusToTicketStatusMap = {
  [FilterStatus.UNKNOWN]: [],
  [FilterStatus.NEW]: [TicketStatus.UNKNOWN],
  [FilterStatus.RESPONSE_SUBMITTED]: [TicketStatus.RESPONSE_SUBMITTED],
  [FilterStatus.PUBLISHED]: [TicketStatus.PUBLISHED],
  [FilterStatus.UNAVAILABLE]: [TicketStatus.UNAVAILABLE],
  [FilterStatus.COMPLETED]: [TicketStatus.COMPLETED],
  [FilterStatus.COMPLETED_EXTERNAL]: [TicketStatus.COMPLETED_EXTERNAL],
  [FilterStatus.UPDATED]: [TicketStatus.REVIEW_UPDATED],
  [FilterStatus.APPROVED_PENDING_PUBLISH]: [TicketStatus.APPROVED_PENDING_PUBLISH],
  [FilterStatus.EDITS_REQUESTED]: [TicketStatus.EDITS_REQUESTED],
};

/**
 * @deprecated use filterStatusToTicketStatusMap instead
 */
export const reviewResponseTicketFiltersToReviewResponseTicketStatusMap = filterStatusToTicketStatusMap;
